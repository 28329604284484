<template>
  <div class="page-404">
    <canvas-bubbling></canvas-bubbling>
    <!-- <div class="content">
      <div class="icons"><i class="ico-airplane-takeoff"></i></div>
      <div class="text">糟糕，你访问的页面飞走了……</div>
      <div class="btns">
        <button @click="handleBackBtnClick">返回首页</button>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  methods: {
    handleBackBtnClick() {
      this.$router.replace({ name: "home" });
    },
  },
};
</script>

<style lang="less" scoped>
.page-404 {
  position: relative;
  width: 100%;
  height: 100%;

  .canvas-bubbling {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
  }

  .content {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding-top: 120px;
    z-index: 2;
    box-sizing: border-box;

    .icons {
      line-height: 280px;
      height: 280px;
      text-align: center;
      i {
        font-size: 220px;
      }
    }

    .text {
      text-align: center;
      font-size: 32px;
      line-height: 64px;
    }

    .btns {
      text-align: center;
      line-height: 180px;
      button {
        width: 180px;
        height: 48px;
        border-radius: 24px;
        border-width: 2px;
        font-size: 18px;
        cursor: pointer;
      }
    }
  }
}
</style>
